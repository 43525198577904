import React from 'react'
import { useIntl } from 'gatsby-plugin-intl';

import step1 from '../../icons/#1.svg';
import step2 from '../../icons/#2.svg';
import step3 from '../../icons/#3.svg';
import step4 from '../../icons/#4.svg';
import step5 from '../../icons/#5.svg';
import step6 from '../../icons/#6.svg';
import step7 from '../../icons/#7.svg';
import circle from '../../slider/circle.svg';

export default () => {
    const intl = useIntl();
    return (
        <div className="process-orl-vuk">
            <div className="main-title">
                {intl.formatMessage({ id: "businessFlowComponent.title" })}
            </div>
            <div className="top-border"/>
            <div className="step step-left step-1">
                <img className="step-1-circle" alt="" src={circle}/>
                <div className="step-image">
                    <img alt="" src={step1}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step1.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step1.description" })}
                </div>
            </div>
            <div className="step step-right">
                <div className="step-image">
                    <img alt="" src={step2}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step2.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step2.description" })}
                </div>
            </div>
            <div className="step step-left">
                <div className="step-image">
                    <img alt="" src={step3}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step3.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step3.description" })}
                </div>
            </div>
            <div className="step step-right">
                <div className="step-image">
                    <img alt="" src={step4}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step4.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step4.description" })}
                </div>
            </div>
            <div className="step step-left">
                <div className="step-image">
                    <img alt="" src={step5}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step5.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step5.description" })}
                </div>
            </div>
            <div className="step step-right">
                <div className="step-image">
                    <img alt="" src={step6}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step6.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step6.description" })}
                </div>
            </div>
            <div className="step step-left step-7">
                <div className="step-image">
                    <img alt="" src={step7}/>
                </div>
                <div className="step-title">
                    <div>{intl.formatMessage({ id: "businessFlowComponent.step7.title" })}</div>
                    <img alt="" src={circle}/>
                </div>
                <div className="step-description">
                    {intl.formatMessage({ id: "businessFlowComponent.step7.description" })}
                </div>
            </div>
        </div>
    );
}
